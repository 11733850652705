<template xmlns="http://www.w3.org/1999/html">
  <b-modal
    id="delete-membership-sidebar"
    ref="delete-membership-sidebar"
    :title="t('Memberships.deleteMembership')"
    :visible="isDeleteMembershipSidebarActive"
    backdrop
    no-header
    hide-footer
    @change="(val) => $emit('update:is-delete-membership-sidebar-active', val)"
  >
    <!--    <template #default="{ hide }">-->
    <template>
      <b-row class="w-100 my-4 ">
        <b-col cols="1"></b-col>
        <b-col cols="11" class="">
         ¿ {{ t('Memberships.message.delete-membership') }}
        </b-col>
        <b-col cols="1"></b-col>
        <b-col cols="11">
          <b-badge pill variant="light-primary">
           {{ selectedMembership.client.last_name+', '+ selectedMembership.client.first_name }}
          </b-badge> ?
        </b-col>
      </b-row>

      <!-- submit button -->
      <div class="container-fluid  mx-0 my-0 px-0 py-0 mb-2">
        <b-row class="w-100">
          <b-col class="col-5 " />
          <b-col class="col-3 float-right">
            <b-button
              variant="secondary"
              data-dismiss="delete-membership-sidebar"
              :disabled="false"
              @click="hideModal"
            >
              {{ t('Clients.buttons.cancel') }}
            </b-button>
          </b-col>
          <b-col class="col-3 float-right ml-1">
            <b-button
              variant="danger"
              @click = "onSubmit(selectedMembership)"
            >
              {{ t('actions.deleteTitle') }}
            </b-button>
          </b-col>
        </b-row>
      </div>
    </template>
  </b-modal>
</template>

<script>
import {
  BBadge,
  BButton, BCol, BRow,
} from 'bootstrap-vue'

import { onUnmounted } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'
import membershipStoreModule from '@/views/Memberships/membershipStoreModule'
import { getUserData } from '@/auth/utils'
import useUsersView from '@/views/Clients/clients-view/useUsersView'

export default {
  components: {
    BBadge,
    BCol,
    BRow,
    BButton,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isDeleteMembershipSidebarActive',
    event: 'update:is-delete-membership-sidebar-active',
  },
  props: {
    isDeleteMembershipSidebarActive: {
      type: Boolean,
      required: true,
    },
    selectedMembership: {
      type: Object,
      default: null,
      required: true,
    },
  },
  methods: {
    hideModal() {
      this.$refs['delete-membership-sidebar'].hide()
    },
  },
  setup(props, { emit }) {
    const MEMBERSHIP_APP_STORE_MODULE_NAME = 'app-membership'
    // Register module
    if (!store.hasModule(MEMBERSHIP_APP_STORE_MODULE_NAME)) store.registerModule(MEMBERSHIP_APP_STORE_MODULE_NAME, membershipStoreModule)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MEMBERSHIP_APP_STORE_MODULE_NAME)) store.unregisterModule(MEMBERSHIP_APP_STORE_MODULE_NAME)
    })

    const toast = useToast()

    // Cambiar a consumirlo desde la propia clase de membership
    const { t, fetchUser } = useUsersView()
    const typeOptions = [
      { label: t('Memberships.type.personal'), value: 0 },
      { label: t('Memberships.type.group'), value: 1 },
    ]
    const onSubmit = selectedMembership => {
      store.dispatch('app-membership/deleteMembership', {
        id: selectedMembership.id,
      })
        .then(async () => {
          emit('update:is-delete-membership-sidebar-active', false)
          emit('handle-update-client')
          toast({
            component: ToastificationContent,
            props: {
              title: 'Aviso',
              text: 'Contratación eliminada',
              icon: 'InfoIcon',
              variant: 'success',
            },
          })
        }).catch(error => {
          console.log(error)
          if (error.response.data) {
            toast({
              component: ToastificationContent,
              props: {
                title: 'Error',
                text: error.response.data.error,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          }
        })
    }

    return {
      typeOptions,
      onSubmit,
      toast,
      t,
      fetchUser,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
#add-membership-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
